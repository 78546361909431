.tab {
    border: 1px solid #5354E4;
    border-radius: 5px;
    background-color: transparent;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
}

.tab:hover {
    background: #5354E4;
    color: #FFFFFF;
}

.tab.active {
    background: #5354E4;
    color: #FFFFFF;
}