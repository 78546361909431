.upload_file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.upload_file_input:hover label {
    background-color: #5354E4;
    color: #ffffff;
}

.upload_file_input.active label {
    background-color: #5354E4;
    color: #ffffff;
}

.upload_file_text {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #333333;
}

.upload_file_input label {
    min-width: 110px;
    max-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #5354E4;
    border-radius: 5px;
    background-color: transparent;
    color: #333333;
    padding: 0.5rem;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    cursor: pointer;
}

.pill {
    padding: 18px 64px;
    width: 270px;
    background-color: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    height: 55px;
    line-height: 20px;
    text-align: center;
    color: #333333;
    cursor: pointer;
}

.pill.active {
    background: #5354E4;
    color: #FFFFFF;
}

.pill:hover {
    background: #5354E4;
    color: #FFFFFF;
}