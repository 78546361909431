.app_inputs {
  display: flex;
  gap: 15px;
}

.app_input_group {
  border: 1px solid #BEBEBE;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  margin-bottom: 20px;
  outline: none;
}

.app_input_group::placeholder {
  color: #333333;
}

.app_input_group:focus-visible {
  border-color: #5354E4;
}

.app_input {
  border: 1px solid #BEBEBE;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  margin-bottom: 20px;
  outline: none;
}

.app_input::-webkit-outer-spin-button,
.app_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app_input[type=number] {
  -moz-appearance: textfield;
}

.app_input::placeholder {
  color: #333333;
}

.app_input:focus-visible {
  border-color: #5354E4;
}

.app_select {
  border: 1px solid #BEBEBE;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: #000;
  margin-bottom: 20px;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(https://cdn-icons-png.flaticon.com/512/2985/2985150.png);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 96% 50%;
}