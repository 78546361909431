.title_button {
    display: flex;
    margin-bottom: 12.5px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.title_button p {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #333333;
}

.title_button button {
    border-radius: 5px;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #333333;
    background-color: transparent;
    border: 1px solid #5354E4;
    padding: 7px 20px;
    height: 30px;
    min-width: 110px;
}

button:hover {
    background: #5354E4;
    color: #FFFFFF;
    cursor: pointer;
}

.app_btn {
    background: #5354E4;
    border-radius: 13px;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 15px 45px;
    border: none;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
    outline: none;
    margin-bottom: 10px;
    min-width: 260px;
}

.app_btn:hover {
    cursor: pointer;
}