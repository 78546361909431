@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  margin:0;
  background-color: #F3F3F3;
  font-family: "Raleway";
}
a:hover svg {
  /* background: #5354E4; */
 border-color: white;
}
.cards {
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
}
.search{
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
}
.form{
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
}
svg{
  color: blue !important;
  
}
.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 30px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E5E7EB;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #5354E4;
}

input:focus + .slider {
 
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.input{
  border: 1px solid #E5E7EB;
}
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #fefefe;
}
.inp{
  background: #FFFFFF;
box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
border-radius: 8px;
}

@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  border-bottom: 0;
}

th:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, .1);
}

.bstyle:focus{
  color:red;
}


@media only screen and (max-width: 599.9px) {
  body {
      background-color: #f3f3f3;
  }

  .app {
      padding: 10px;
      padding-top: 50px;
      min-height: 100vh;
      height: auto;
      background-color: transparent;
  }

  .modal {
      width: 100%;
      min-width: 100%;
      padding: 30px 10px 20px;
      max-height: 100%;
  }

  .title {
      font-size: 30px;
  }

  .app_form {
      margin-top: 10px;
      margin-bottom: 10px;
  }

  .pill {
      width: 100%;
      height: auto;
      padding: 12px 64px;
  }

  .modal-title {
      margin-bottom: 20px;
      text-align: center;
  }

  .tab {
      height: auto;
      padding: 10px 0;
      font-size: 12px;
  }

  .app_input {
      padding: 10px;
      font-size: 15px;
  }

  .app_select {
      padding: 10px;
      font-size: 15px;
  }

  .app_input_group {
      padding: 10px;
      font-size: 15px;
  }
}

@media only screen and (max-width: 1460px) and (min-width: 600px) {
  .app {
      min-height: 100vh;
      height: auto;
      padding: 20px 40px;
  }
  .pills {
      justify-content: center;
  }
  .pill {
      width: 220px;
      padding: 18px 40px;
  }
  .modal {
      width: 100%;
      max-width: 600px;
      padding: 20px;
      max-height: 100%;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Raleway';
}

.app {
  width: 100%;
  height: 100vh;
  background-color: #F3F3F3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 570px;
  max-height: 590px;
  overflow-x: auto;
  padding: 40px 80px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  scroll-padding: 20px;
}

.modal::-webkit-scrollbar {
  width: 4px;
  border-radius: 10px;
}

.modal::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 10px;
}

.modal::-webkit-scrollbar-thumb {
  background: #5354E4;
}

.modal::-webkit-scrollbar-thumb:hover {
  background: #26269b;
}

.modal_title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: #333333;
  margin-bottom: 25px;
}

.title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  color: #333333;
  margin-bottom: 10px;
}

.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #333333;
  margin-bottom: 10px;
}

.app_form {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
}

.tabs {
  margin-top: 12.5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
