.logreg {
    display: flex;
    gap: 5px;
}

.logreg p {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
}

.logreg button {
    border: none;
    background-color: transparent;
    outline: none;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    color: #5354E4;
    cursor: pointer;
}

.reset {
    display: flex;
    justify-content: flex-end;
}

.reset_text {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    text-align: right;
    text-decoration-line: underline;
    color: #333333;
    cursor: pointer;
}